import React from 'react'
import './Footer.scss'

export default ({ handleShowModal }) => {

    return (
        <footer>
            <div className="container">
                <div className="main_footer">
                    <p className="foot_app">photomosaic.app © 2023</p>
                    {/* <p className="privacy_policy_main">All Rights Reserved | <span onClick={() => handleShowModal(true)}>Privacy Policy</span></p> */}
                    <p className="privacy_policy_main">All Rights Reserved | <a href='/privacy.html'>Privacy Policy</a></p>
                </div>
            </div>
        </footer>
    )

}