import React from 'react';
import logo from '../Header/images/logo.png';
import check_icon from './images/check.png'
import error_icon from './images/cross.png'

import '../Header/Header.scss';
import './Success.scss'

export default ({ type }) => {

    return (
        <header>
            <div className="container">
                <div className="logo_application">
                    <img src={logo} alt="" />
                </div>
            </div>

            <div className="parent_header parent_header_sign_in">

                <div className="message_login">
                    <img src={(type == 'error' ? error_icon : check_icon)} alt="" />

                    <p>
                        {
                            type == 'error' ? 'Something went wrong ' : 'You have successfully signed in.'
                        }

                    </p>
                </div>

            </div>

        </header>
    );
}