import React, { useState } from 'react';
import logo from './images/logo.png';
import icon_lg from './images/lg_logo.png';
import icon_tizen from './images/tizen_logo.svg';
import icon_android from './images/android_logo.svg';

import './Header.scss';

export default () => {

    const [devices] = useState([
        { id: 0, name: 'Lg', icon: icon_lg, text: 'Available on LG' },
        { id: 1, name: 'tizen', icon: icon_tizen, text: 'Available on Samsung Smart TV' },
        { id: 2, name: 'Lg', icon: icon_android, text: 'Available on Google TV' },
    ]);

    return (
        <header>
            <div className="container">
                <div className="logo_application">
                    <img src={logo} alt="" />
                </div>
            </div>

            <div className="parent_header">
                <div className="container">
                    <div className="main_header">
                        <div className="main_header__title">
                            <h1>Photo Mosaic</h1>
                            <h3> Relive your life's most memorable moments on the big screen, together with your friends and family.</h3>
                            <p>Download the app for free from Smart TV content stores today!</p>
                            <div className="available_devaces">
                                {
                                    devices.map((device) => {
                                        return (
                                            <>

                                                <div className="available_devaces__item" key={device.id} >
                                                    <div className="icon_device_main">
                                                        <img src={device.icon} className="icon_device" alt='' />
                                                    </div>
                                                    <p className='text_device_card'>{device.text}</p>
                                                </div>

                                            </>

                                        )
                                    })
                                }

                            </div>
                        </div>
                        <div className="main_header__screen">
                            <div className="inner_main_header__screen"></div>
                        </div>
                    </div>
                </div>
            </div>

        </header>
    );
}