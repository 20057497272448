import { useState } from 'react';
import { Route, Switch } from "react-router-dom";


import Header from './components/Header/Header';
import About from './components/About/About';
import Footer from './components/Footer/Footer';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';

import './App.css';
import React from 'react';
import SignInGoogle from './components/SignInGoogle/SignInGoogle';
import MessageGoogle from './components/success/MessageGoogle';

export default () => {

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (value) => {
    setShowModal(value);
  }

  return (
    <>
      <Switch>
        <Route exact path={'/'}>
          <Header />
          <About />
          <Footer handleShowModal={handleShowModal} />
          {showModal && <PrivacyPolicy handleShowModal={handleShowModal} />}
        </Route>
        <Route path={'/login'}>
          <SignInGoogle />
        </Route>
        <Route path={'/success'}>
          <MessageGoogle type='success' />
        </Route>
        <Route path={'/error'}>
          <MessageGoogle type='error' />
        </Route>
      </Switch>
    </>
  )
}
