import React, { useState } from "react";

import "./About.scss";

export default () => {

    const [listAboutUs] = useState([
        { num: 1, text: 'Go to your TV content store' },
        { num: 2, text: 'Search for PhotoMosaic' },
        { num: 3, text: 'Download the app' },//Install and Join
    ])

    return (
        <section>
            <div className="container">
                <div className="parent_about_us">
                    <h2 className="title_about_us">About the Photo Mosaic App</h2>
                    <p className="description_about_us">
                        The Photo Mosaic App is an application that allows you to display a collage of photos on your smart TV, creating a captivating and joyful atmosphere in your home. The app is designed to make it easy for you to share cherished memories with your loved ones. By connecting the app to your Google account, you can conveniently share photos from your Google photo albums directly to the TV app.
                        <p className="">
                            The developers of the Photo Mosaic App understand the importance of privacy and assure users that their personal information and data are handled with utmost care. They have a Privacy Policy in place to ensure that user data is protected. If you have any concerns or questions about your privacy while using the app, you can contact the support team at
                            <a href="mailto:HELP@PHOTOMOSAIC.APP"> HELP@PHOTOMOSAIC.APP</a>  for assistance. <br />
                        </p>
                        Using the Photo Mosaic App, you can embrace the power of your high-definition TV screen to create a vibrant and heartwarming ambience in your home. It provides a unique and engaging way to share and relive special moments with your friends and family, making every moment memorable.
                    </p>
                    <div className="list_about_us">
                        {
                            listAboutUs.map((item) => {
                                return (
                                    <div className="list_about_us__item" key={item.num}>
                                        <div className="num_list_about_us">
                                            <p>{item.num}</p>
                                        </div>
                                        <p className="text_list_about_us">
                                            {item.text}
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section >
    )

}