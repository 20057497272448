import React, { useEffect, useState, useRef } from 'react';
import { useRouter } from '../../hooks/router'
import logo from '../Header/images/logo.png';

import '../Header/Header.scss';
import './SignInGoogle.scss'

export default () => {

    const router = useRouter()

    const [deviceInfo, setDeviceInfo] = useState({})
    const ref_info = useRef(null)

    useEffect(() => {

        if (router.query.device_id) {
            ref_info.current = router.query.device_id
            router.push('/login')
        }

    }, [])

    const signInBtn = () => {

        // WARNING: For GET requests, body is set to null by browsers.

        window.location.href = 'https://gphoto-api.inorain.com/api/auth/google?device_id=' + ref_info.current

    }

    return (
        <header>
            <div className="container">
                <div className="logo_application">
                    <img src={logo} alt="" />
                </div>
            </div>

            <div className="parent_header parent_header_sign_in">
                <div className="form_sign_in_google">
                    <div className="container">
                        <div className="form_sign_in_google">

                            <h1>Log in</h1>

                            <p> Welcome back!</p>

                            <button className='login-with-google-btn' onClick={signInBtn} >
                                Sign in with Google
                            </button>

                        </div>
                    </div>
                </div>
            </div>

        </header>
    );
}